import React, { useEffect, useRef } from 'react';
import * as d3 from "d3";

const Graficad3 = () => {
const myReference = useRef(null);

useEffect(() => {
// set the dimensions and margins of the graph
const margin = {top: 50, right: 30, bottom: 30, left: 60},
    width = 460 - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom + 100;

// append the svg object to the body of the page
const svg = d3.select(myReference.current)
  .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
  .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

//Read the data
d3.csv("https://raw.githubusercontent.com/holtzy/data_to_viz/master/Example_dataset/3_TwoNumOrdered_comma.csv",

  // When reading the csv, I must format variables:
  function(d){
    return { date : d3.timeParse("%Y-%m-%d")(d.date), value : d.value }
  }).then(

  // Now I can use this dataset:
  function(data) {

    // Add X axis --> it is a date format
    const x = d3.scaleTime()
      .domain(d3.extent(data, function(d) { return d.date; }))
      .range([ 0, width ]);
    svg.append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x))
      .attr("class", "stroke-skin-base fill-skin-fill");

    // Add Y axis
    const y = d3.scaleLinear()
      .domain([0, d3.max(data, function(d) { return +d.value; })])
      .range([ height, 0 ]);
    svg.append("g")
      .call(d3.axisLeft(y))
      .attr("class", "stroke-skin-base fill-skin-fill");
      // .style("stroke", "--c-sie");

    // Add the line
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      // .attr("stroke", "white")
      .attr("class", "stroke-skin-base")
      .attr("stroke-width", 1.5)
      .attr("d", d3.line()
        .x(function(d) { return x(d.date) })
        .y(function(d) { return y(d.value) })
        )

})
});
  return (
    <svg className='hover:border-2 w-8/12' viewBox='0 0 500 500' preserveAspectRatio="xMidYMid meet" ref={myReference}></svg>
  )
}

export default Graficad3
