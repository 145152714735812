import './App.css';
import Topbar from './Topbar';
import CardsContainer from './CardsContainer';
import Sidebar from './Sidebar';

function App() {
  return (
  <div id="miid" className='theme-place h-screen grid grid-cols-[200px_1fr] '>
    <div className="">
      <Sidebar/>
    </div>
      <div className='grid grid-rows-[50px_1fr] gap-0'>
      <Topbar/>
      <CardsContainer/>
      </div>
  </div>
  );
}

export default App;
