import React from 'react'
// import Cardsvg from './Cardsvg'
import Radiald3 from './Radiald3'
import Graficad3 from './Graficad3'
// import Mapad3 from './Mapad3'
import SvgMap from './SvgMap'
import Grafod3 from './Grafod3'
import Barrasd3 from './Barrasd3'
import Arbold3 from './Arbold3'
import CardTemplate from './CardTemplate'

function CardsContainer() {
  return (
    <div className='bg-gradient-to-r from-cua to-uno grid grid-cols-12 grid-rows-6 gap-4 p-5 '>
        <CardTemplate ><Graficad3/></CardTemplate>
        <CardTemplate><Barrasd3/></CardTemplate> 
        <CardTemplate><Radiald3/></CardTemplate>
        <CardTemplate><Arbold3/></CardTemplate>
        <CardTemplate><SvgMap/></CardTemplate>
        <CardTemplate><Grafod3/></CardTemplate>
    </div>
  )
}

export default CardsContainer