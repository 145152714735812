import React from 'react'

const CardTemplate = (props) => {
  //console.log(props)
  // const claseTail = 
    // <div className={'border-solid border-4 rounded-lg m-5 h-full w-full col-span-'+props.colSpan}>Graficad3
    // <div className='bg-tre text-cin border-solid border-4 rounded-lg col-span-4 row-span-3 flex align-middle'>Graficad3
  return (
    <div className='bg-gradient-to-r from-uno to-uno text-cin border-solid border-4 rounded-lg col-span-4 row-span-3 flex align-middle'>Graficad3
            {props.children}
    </div>
  )
}

export default CardTemplate