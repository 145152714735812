
import React, { useEffect, useRef } from 'react';
import * as d3 from "d3";

const SvgMap = () => {
const myReference2 = useRef(null);

function withOpacity(variableName) {
  return ({ opacityValue }) => {
    if (opacityValue !== undefined) {
      return `rgba(var(${variableName}), ${opacityValue})`
    }
    return `rgb(var(${variableName}))`
  }
}

useEffect(() => {
    // The svg
const svg = d3.select(myReference2.current),
    width = +svg.attr("width"),
    height = +svg.attr("height");

// Map and projection
const projection = d3.geoNaturalEarth1()
    .scale(width / 1.3 / Math.PI)
    .translate([width / 2, height / 2+50])

// Load external data and boot
d3.json("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson").then( function(data) {

    // Draw the map
    svg.append("g")
        .selectAll("path")
        .data(data.features)
        .join("path")
            .attr("fill", withOpacity("--c-cin"))
            .attr("d", d3.geoPath()
            .projection(projection)
            )
            .style("stroke", "none")

})
});

  return (
    <svg className='hover:border-2 w-8/12' id="my_dataviz" ref={myReference2} width="440" height="320"></svg>

  )
}

export default SvgMap