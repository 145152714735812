import React from 'react'
import { useState } from 'react';

function Sidebar() {

const [Tema, setTema] = useState("default");
const ChangeSel = (value) => {
  setTema(value.target.value)
  const oldtheme = document.getElementById("miid").className;
  const mili = oldtheme.split(" ");
  mili[0]=value.target.value;
  const newval = mili.join(" ");
  console.log(newval );
  console.log( getComputedStyle(document.documentElement).getPropertyValue('--color-fill'));
  //console.log(document.querySelector('.theme-neon').getPropertyValue('--color-fill'));
  document.getElementById("miid").className = newval  ;
  // document.getElementById("temaSel").value = newval  ;
};
    //const [Tema, setTema] = useState({value:"dark"});
          //<select className='bg-gray-500 rounded-md text-white p-1' value={Tema} onChange={e => setTema({value:e.target.value})} ></select>
  return (
    <div className="bg-gradient-to-r from-uno to-tre h-full">
      <div className="rounded text-sei text-center align-middle h-4 p-2">Sidebar</div>
      <div className="rounded bg-uno p-2 mx-2 my-20 py-10">
        <div className="rounded my-3 border-white border-2 text-center text-cin p-4">Herarqy</div>
        <div className="rounded my-3 border-white border-2 text-center text-cin p-4">Distributed</div>
        <div className="rounded my-3 border-white border-2 text-center text-cin p-4">Centralized</div>
      </div>
      <div className="pl-2 pt-96 rounded-md ">
          <select className='bg-uno rounded-md text-sei m-7 p-2' value={Tema} onChange={ChangeSel} >
            <option value="theme-defaul">defaul</option>
            <option value="theme-light">light</option>
            <option value="theme-dark"> dark </option>
            <option value="theme-jail">jail</option>
            <option value="theme-nature">nature</option>
            <option value="theme-semineon">semineon</option>
          </select>
      </div>
    </div>
  );
}

export default Sidebar